import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    map: {
        //paddingLeft: "18px",
        width: "100%",
        height: "500px",
        marginLeft: "9px",
        
        [theme.breakpoints.down('sm')]: {
            marginLeft: "8px",
            height: "300px"
        },

    }
});

class Map extends React.Component {
    

  componentDidMount() {
    
  }

  render() {
      const {classes} = this.props
    return (
        <iframe title="Kenneth E. Kaiser | Attorney at Law" className={classes.map} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2959.434133227929!2d-88.0465261486996!3d42.11958847910172!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880fa4f317c757b9%3A0xc5dd2bd4bee0a19d!2s502+N+Plum+Grove+Rd%2C+Palatine%2C+IL+60067!5e0!3m2!1sen!2sus!4v1552210379875"   frameBorder="0"  allowFullScreen ></iframe>
    );
  }
}

export default withStyles(styles)(Map);
