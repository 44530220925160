import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {Button, Grid, TextField, Typography} from '@material-ui/core/';
import Axios from 'axios';



const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    background: "#fff",
    borderRadius: "3px",
    [theme.breakpoints.down('sm')]: {
      width: "100%"
    },
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 300,
  },
  buttonGrid: {
    width: "100%",
    padding: "20px"
  },
  button: {
   minWidth: "100px"
  },
  success: {
    [theme.breakpoints.down('sm')]: {
      fontSize: "20px"
    },
  },
  error: {
    color: "#ec0b0b",
    [theme.breakpoints.down('sm')]: {
      fontSize: "20px"
    },

  }
});



class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      subject: "",
      phone: "",
      email: "",
      message: "",
      success: false,
      error: ""

    };
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit(e){
    e.preventDefault();
    let request  = {
      method: 'post',
      url: 'https://sr1vgtrji7.execute-api.us-east-2.amazonaws.com/production/contact-us',
      headers: {
        'Content-Type': "application/json",
      },
      data: {
        "name": this.state.name,
        "email": this.state.email,
        "phone": this.state.phone,
        "subject": this.state.subject,
        "message": this.state.message
      },
    }
  
    Axios.request(request).then(() => {
      this.setState({
        success: true,
        error: "",
        name: "",
        subject: "",
        phone: "",
        email: "",
        message: "",
      })
    })
    .catch(() => {
      this.setState({
        success: false,
        error: "Something went wrong! We were unable to process your information. Please try again or send an email to kkaiser264@aol.com"
      })
    })

  };



  render(){
    const {classes } = this.props;
  
    
    if(this.state.success){
      return (
        <Grid item xs={12}>
          <Typography color="primary" align="center" className ={classes.success} variant="h4" >
          Your information was successfully sent! We will contact you shortly.
          </Typography>
        </Grid>
      );  
    } 

    return (
      <React.Fragment>
        
        <form
            name="contact"
            onSubmit={this.handleSubmit.bind(this)}
          >
          <TextField
            required
            id="name"
            label="Name"
            name="name"
            value={this.state.name}
            className={classes.textField}
            onChange={this.handleChange.bind(this)}
            margin="normal"
            variant="filled"
          />
          <TextField
            required
            id="email"
            label="Email"
            className={classes.textField}
            type="email"
            name="email"
            value={this.state.email}
            onChange={this.handleChange.bind(this)}
            autoComplete="email"
            margin="normal"
            variant="filled"
          />
          <TextField
            required
            id="phone"
            label="Phone Number"
            name="phone"
            value={this.state.phone}
            onChange={this.handleChange.bind(this)}
            className={classes.textField}
            margin="normal"
            variant="filled"
          />
          <TextField
            required
            id="subject"
            name= "subject"
            label="Subject"
            multiline
            fullWidth
            value={this.state.subject}
            className={classes.textField}
            onChange={this.handleChange.bind(this)}
            margin="normal"
            variant="filled"
          />
          <TextField
            required
            id="message"
            name= "message"
            label="Message"
            onChange={this.handleChange.bind(this)}
            value={this.state.message}
            multiline
            rows="10"
            fullWidth
            className={classes.textField}
            margin="normal"
            variant="filled"
          />

          <Grid className={classes.buttonGrid} spacing={24} container justifycontent="center">
            <Grid item xs={12} md={2}>
              <Button type="submit" variant="contained" color="primary" className={classes.button}>
                Send Message
              </Button>
            </Grid>
          </Grid>
        </form>
        {
          (this.state.error)? <Grid item xs={9}><Typography color="primary" align="center" className ={classes.error} variant="h5" >{this.state.error}</Typography></Grid>: null
        }
      </React.Fragment>
    );
  }
}


export default withStyles(styles)(ContactForm);
