import React from "react"
import {  Grid,  Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Layout from '../components/layout';
import Footer from '../components/footer';
import ContactForm from '../components/contactForm';
import Map from '../components/map';
import SEO from '../components/seo';


const styles = theme => ({
  gridContainer: {
    width: "100%",
    marginTop: "56px",
    minHeight: "500px",
    height: "100%",
    display: "flex",
    direction: 'column',
    justifyContent: "center",
    alignItems: "center",

  },
  gridContainerSecond: {
    width: "100%",
    height: "auto",
    display: "flex",
    direction: 'column',
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    paddingTop: "50px",
    paddingLeft: "10px",
    paddingRight:"30px",
    paddingBottom:"50px",
    background: "linear-gradient(to bottom, #4A6476, #ccc)"
  },
  headline: {
    color: "#fff",
    zIndex:5,
    width: "100%",
    textAlign: "center",
  },
  phone: {
    textDecoration: "none",
    color: "inherit"
  }
});



class Contact extends React.Component {


  render(){
    const {classes, data } = this.props;

    return (
    <Layout>
      <SEO pagePath ="/contact/"  pageTitle = "Contact Us" keywords = "Attorney, Cook County, Lake County, McHenry County, Kane County and DuPage County" pageDescription = "Call us at 847-991-6675 to speak with us about your case. Free consultations and case assessments are available by appointment. Off hours and weekend appointments are available." image = {data.shareImage.childImageSharp.resize.src} imageHeight="400" imageWidth="400"/>
      <Grid className = {classes.gridContainer} container  >
        <Grid className = {classes.gridContainerSecond} spacing={24} container>
          <Grid item xs={12} >
            <Typography className={classes.headline} variant="h3" >
              Contact Us
            </Typography>
          </Grid>
          <Grid item xs={12} md={8}  lg={6}>
            <Typography className={classes.headline} variant="h6" >
              Call us at <a className={classes.phone} href="tel:8479916675">847-991-6675</a> to speak with us about your case. Free consultations and case assessments are available by appointment. Off hours and weekend appointments are available.
            </Typography>
          </Grid>
          <ContactForm/>
          <Grid item xs={12} >
                <Typography className={classes.headline} variant="h3" >
                Map for Directions
                </Typography>
            </Grid>
          <Grid container justify = "center" >
            <Grid  item  xs={12} sm={12} md={9} lg={7} >
              <Map/>
            </Grid>
          </Grid>

        </Grid>

        <Footer/>
      </Grid>
    </Layout>
  );
  }

}


export default withStyles(styles)(Contact);

export const pageQuery = graphql`
  query {
    shareImage: file(relativePath: {eq: "Kaiser.jpg"}) {
      childImageSharp {
        resize(width: 400, height: 400) {
          src
        }
      }
    }
  }
`